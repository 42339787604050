#components-form-demo-normal-login .login-form {
    max-width: 300px;
}
.login-form-forgot {
    color: #CC8003;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}
.login-form-forgot:hover {
    color: #e0a435;
}
  
#consentInformation {
    font-size: 12px;
    font-style: italic;
    font-family: 'Euclid-Bold', sans-serif;
    font-weight: 500;
}
