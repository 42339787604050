
.navbar {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  svg {
    width: 4rem;
    height: 4rem;
  }
}


.page-head {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  h1 {
    flex-grow: 1;
    margin-bottom: 0;
  }
}


//AntDesign overrides
.ant-table-content {
  font-size: 1rem;
}

.ant-calendar-range-picker-input{
  display: inline;
}

.ant-table-row{
  cursor: pointer;
}