.form {
  background-color: #ECECEC;
  margin: 0.5rem auto;
  padding: 0.8rem 1.5rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 100%;
}


.mapContainer {
  height: 184px;
  width: 95%;
  border-radius: 6px;
  background-color: #FFFFFF;
  margin: 0.5rem 0.5rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
}

.map {
  height: 130px;
  width: 100%;
  margin: auto;
}

.shopNameMap {
  font-size: 1.25rem;
  color: black;
  margin:1rem 0 0.75rem 1.5rem;
}

.cursor {
  cursor: pointer;
}

.heading {
  color: #000000;
  font-family: Euclid-Regular;
  font-size: 1.875rem;
  line-height: 1.875rem;
  margin-bottom: 1rem;
  margin-top: -2.5rem;
}

.storesHeading {
  color: #000000;
  font-family: Euclid-Regular;
  font-size: 1.875rem;
  line-height: 1.875rem;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
}

.sectionTitle {
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-top: 1.5rem;
  margin-bottom: 0.2rem;
}

.sectionValue {
  font-size: 1.5rem;
  line-height: 1.875rem;
}

.qrCode {
  margin: 3rem auto;
  padding: 0.5rem;
  width: 70%;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  
  img {
    margin: auto;
    border-radius: 6px;
    max-width: 100%
  }
}

.cutOut {
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
  display: block;
  position: relative;
  top: -4.5rem;
  margin: 0 auto;
  background: white;
}

.head {
  margin-top: 5rem;
}

.rectangle-2 {
  height: 526px;
  width: 327px;
}

.showStores {
  margin: auto;
  height: 48px;
  width: 200px;
  border-radius: 32px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 12px 0 rgba(0,0,0,0.1);
}