.form {
    background-color: #ECECEC;
    margin: auto;
    padding: 5rem 0;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.08);
    border-radius: 8px;
    width: 50%;
}

.heading {
    color: #CC8003;
}

.head {
    margin-top: 5rem;
}

.rectangle-2 {
    height: 526px;
    width: 327px;
}