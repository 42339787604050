
$primary: #CC8003;
$secondary: #000000;

$blue: #213A8F;
$blue2: #0072BB;
$green: #65B32E;
$orange: #EF7D00;
$red: #E63612;

$gray_1: #000000;
$gray_2: #3D3D3D;
$gray_3: #575757;
$gray_4: #707070;
$gray_5: #878787;
$gray_6: #C6C6C6;
$gray_7: #ECECEC;
$gray_8: #FFFFFF;

