
body {
    font-family: 'Euclid-Regular', sans-serif;
}

h1 {
    font-size: 2.1rem;
}
/*
.ant-btn-primary {
    background-color: #FF5733 !important;
    border-color: #FF5733 !important;
}
    */

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        /* padding-top: 50px; */
    }
}
input{
    border-radius: 0 !important;
}
.ant-input:focus, .ant-input:hover {
    outline: none !important;
    box-shadow: none !important;
    border-color: #CC8003 !important;
    border-radius: 0 !important;
}

.ant-input:focus {
    border-color: #CC8003 !important; /* Change the border color */
    box-shadow: 0 0 0 2px rgba(222, 186, 129, 0.5) !important; /* Subtle glow effect around input */
    outline: none !important; /* Removes default outline */
}


.ant-btn-primary {
    border-radius: 0 !important;
    background-color: #CC8003 !important;
    border-color: #CC8003 !important;
}

.ant-btn-primary:hover {
    background-color: #e68905 !important;
    border-color: #e68905 !important;
}

.ant-modal-footer {
    color: #CC8003 !important;
}

.ant-modal-content {
    border-radius: 8px !important; 
}

.ant-modal-close {
    color: #CC8003 !important;
}

