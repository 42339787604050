.form {
    background-color: #F9F0E1;
    margin-top: 5rem;
    padding: 5rem 0;
}

.heading {
    color: #CC8003;
}

.head {
    margin-top: 5rem;
}