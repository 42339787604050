@import "~antd/../../src/scss/colors.module.scss";

.link span,
.link {
	font-size: 1.15rem;
	display: inline-block;

	&:hover, &:focus, &.active {
		color: $primary;
	}
}

:global {
	.navbar {
		.main-menu {
			justify-content: center;
		}
		.secondary-menu {
			justify-content: flex-end;
			flex-grow: 0;
		}
		.navbar-nav .nav-link {
			color: $gray_4;

			&.active, &:hover, &:focus {
				color: $primary;
			}
		}
	}
}