@font-face {
  font-family: 'Euclid-Regular';
  font-display: swap;
  src: url('/fonts/Euclid-Circular-A-Regular.ttf') format('ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Euclid-Medium';
  font-display: swap;
  src: url('/fonts/Euclid-Circular-A-Medium.ttf') format('ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Euclid-Bold';
  font-display: swap;
  src: url('/fonts/Euclid-Circular-A-Bold.ttf') format('ttf');
  font-weight: 500;
}
