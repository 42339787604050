@import "~antd/../../src/scss/colors.module.scss";

.container {
  position: fixed;
  max-height: 53rem;
  overflow: auto;
  background: #F9F0E1;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 3rem 0;
}

.name {
  font-size: 1.875rem;
  color: $primary;
  margin-bottom: 2rem;
}

.label {
  font-size: 1.25rem;
  color: $primary;
}

.value {
  font-weight: 400;
  font-size: 1rem;
  color: $secondary;
}

.row {
  margin-bottom: 1.25rem;
}

.editButton {
  background: $primary;
  border: 0;
  font-size: 1.25rem;
  border-radius: 0;

  &:hover, &:focus {
    background: $primary;
  }
}

.mapContainer {
  height: 184px;
  width: 448px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
}

.map {
  height: 130px;
  width: 448px;
}

.shopNameMap {
  font-size: 1.25rem;
  color: black;
  margin:1rem 0 0.75rem 1.5rem;
}

.checkedIn {
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background-color: #65B32E;
}

.notCheckedIn {
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background-color: #EF7D00;
}

.action {
  color: $primary;

  svg {
    width: 2rem;
    height: 2rem;
  }
}

.close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.prev {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
}

.next {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
}

.toggleMapButton {
  cursor: pointer;
}